import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import * as Yup from "yup"
import { Formik } from "formik"
import {
  IconButton,
  TextField as MuiTextField,
  Grid,
  Card,
  Typography,
  Divider,
  Tooltip,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { spacing } from "@material-ui/system"
import {
  CheckCircleIcon,
  Hash,
  Phone,
  MapPin,
  Mail,
  Edit,
  Slash,
} from "@iconsGallery"
import * as Utils from "@utils"
import _, { isEmpty, isString } from "lodash"
import * as ServiceAPI from "@services"
import * as Features from "@features"
import * as Adm from "@adm"
import { useTranslation } from "react-i18next"
import * as constantsAPI from "@constants"
import { JSUtils } from "@utils"

const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

const FormMainWrapper = styled.form`
  @media (min-width: 1024px) and (max-width: 1540px) {
    .cls-location-name-wrapper {
      border: none;
    }
    .MuiGrid-grid-lg-3 {
      max-width: 25% !important;
      flex-basis: 50% !important;
    }
  }
`
const TextField = styled(MuiTextField)(spacing)
const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexWrap: "wrap" },
  margin: { margin: theme.spacing(1) },
  withoutLabel: { marginTop: theme.spacing(3) },
  phonefield: {
    "& .react-tel-input": {
      width: "250px",
    },
  },
  textField: {
    marginBottom: "5px",
    marginLeft: "15px",
    width: "90%",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "var(--primaryColor)",
    },
  },
  text: {
    paddingLeft: "20px",
    marginLeft: "0px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  text2: {
    paddingLeft: "20px",
    marginLeft: "0px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "230px",
    marginBottom: "10px",
  },
  text1: {
    marginBottom: "20px",
    paddingLeft: "0px",
    marginLeft: "10px",
  },
  textemail: {
    paddingLeft: "20px",
    marginLeft: "0px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "230px",
  },
  textHeader: {
    marginLeft: "10px",
    marginBottom: "14px",
    fontWeight: "500",
    fontSize: "16px",
  },
  textHeader1: {
    marginBottom: "14px",
    paddingLeft: "20px",
    marginLeft: "0px",
    fontWeight: "600",
  },
  textHeader2: {
    marginLeft: "0px",
    marginBottom: "18px",
    fontWeight: "600",
    paddingLeft: "20px",
  },
  icon: {
    marginRight: "18px",
    color: "var(--primaryColor)",
    marginBottom: "-5px",
    fontSize: "30px",
    "@media(max-width: 1120px)": {
      marginRight: "4px",
      width: "14px",
    },
  },
  actionIcons: {
    color: "rgba(158, 158, 158, 1)",
    fontSize: "22px",
  },

  header2: {
    marginBottom: "15px",
    marginLeft: "10px",
    fontWeight: "800",
  },
  divider: {
    padding: "0.5em",
    minHeight: "80px",
    "@media(min-width: 600px)": {
      borderRight: "0.1em solid rgba(0, 0, 0, 0.1)",
    },
  },
  noDivider: {
    padding: "0.5em",
    minHeight: "80px",
  },
  divPadding: {
    padding: "2px",
  },
  smallTextInput: {
    width: "230px !important",
    height: "36px",
    marginBottom: "25px",
    marginRight: "18px",
  },
  autoCompleteHeight: {
    "& .MuiAutocomplete-inputRoot": {
      height: "38px",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px 4px !important",
    },
  },
}))

function LocationAddress(props) {
  const {
    data = {},
    setAllowPrompt = () => {},
    isWriteAllowed = false,
    isTablet = false,
    countryList = [],
  } = props
  const classes = useStyles()
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const { t } = useTranslation()
  const [isAnyChanges, setAnyChanges] = React.useState(false)
  const [countryOpen, setCountryOpen] = React.useState(false)
  const [stateOpen, setStateOpen] = React.useState(false)
  const [isPhoneField, setPhoneField] = useState({ phoneCountryCode: "" })
  const [errorMessage, setErrorMessage] = useState({})

  useEffect(() => {
    props.fetchStateData({
      value: countryList.find(
        (x) => x.name === _.get(data, "locationAddress.country", "")
      ),
    })
  }, [data, countryList])

  const { locationId } = data || {}

  const onGlobalUpdate = () => {
    setAnyChanges(true)
  }

  const handleFetchStateData = async (value, type, method) => {
    const IdFilterObj = [
      { field: "country_id", operator: "contains", value: value.id },
      { field: "status", operator: "eq", value: "true" },
    ]
    const body = {
      filter: [...IdFilterObj] || [],
      sort: { state: 1 },
      limit: 1000,
    }
    if (Utils.JSUtils.isEmpty(value) === false) {
      let response = await ServiceAPI.fetchCommonData(
        API_END_POINTS.getAllState,
        body
      )
      let { data: { data = [] } = {} } = response || {}
      let stateList = []
      data?.docs?.map((o) =>
        stateList.push({ id: o._id, name: o.state, code: o.alpha2_code })
      )
      if (method === "formik") {
        return stateList
      } else {
        props.setStateList(stateList)
      }
    }
  }

  return (
    <div>
      <Card
        className="cls-locationaddress"
        style={{
          width: "100%",
          boxShadow:
            "0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
          borderRadius: "8px",
        }}
      >
        <Adm.BackdropOverlay open={loading} />
        {Utils.JSUtils.isEmpty(locationId) === true ? (
          <Features.NoRecordFound />
        ) : (
          <>
            <Formik
              enableReinitialize
              initialValues={{
                location: _.get(data, "locationId", ""),
                locationName: _.get(data, "locationName", ""),
                phoneNumber:
                  _.get(data, "phoneNumber.countryCode", "") +
                  (_.get(data, "phoneNumber.number", "") === 0
                    ? ""
                    : _.get(data, "phoneNumber.number", "")),
                emailAddress: _.get(data, "locationSupportEmail", ""),
                locationManager: _.get(data, "locationManager", ""),
                assistantManager: _.get(data, "assistantManager", ""),
                line1: _.get(data, "locationAddress.line1", ""),
                line2: _.get(data, "locationAddress.line2", ""),
                postalcode: _.get(data, "locationAddress.postalcode", ""),
                state: props?.stateList.find(
                  (item) =>
                    item.name === _.get(data, "locationAddress.region", "")
                ), //_.get(data, "locationAddress.region", ""),
                country: countryList.find(
                  (item) =>
                    item.name === _.get(data, "locationAddress.country", "")
                ), //_.get(data, "locationAddress.country", ""),
                city: _.get(data, "locationAddress.city", ""),
                countryCode: _.get(data, "phoneNumber.countryCode", ""),
                latitude: _.get(data, "locationGeoPoint.coordinates[1]", ""),
                longitude: _.get(data, "locationGeoPoint.coordinates[0]", ""),
                timezone: _.get(data, "locationTimezone", ""),
              }}
              validationSchema={Yup.object().shape({
                location: Yup.string()
                  .required("Enter Location Number")
                  .matches(
                    Utils.JSUtils.getYupValidationCheck(
                      "^[A-Za-z0-9_@./#+-]*$"
                    ),
                    "Enter valid Location Number"
                  )
                  .min(1, "Location Number can be of min-1 max-255 characters")
                  .max(
                    255,
                    "Location Number can be of min-1 max-255 characters"
                  )
                  .strict(true),
                locationName: Yup.string()
                  .required(`Enter Location Name`)
                  .matches(
                    Utils.JSUtils.getYupValidationCheck("^[a-zA-Z0-9_-\\s]+$"),
                    "Enter valid Location Name"
                  )
                  .min(1, "Location Name can be of min-1 max-255 characters")
                  .max(255, "Location Name can be of min-1 max-255 characters")
                  .strict(true),
                locationManager: Yup.string()
                  .required(`Enter Location Manager Name`)
                  .min(
                    1,
                    "Location Manager Name can be of min-1 max-255 characters"
                  )
                  .max(
                    255,
                    "Location Manager Name can be of min-1 max-255 characters"
                  )
                  .matches(
                    Utils.JSUtils.getYupValidationCheck(/^[aA-zZ\s]+$/),
                    "Only alphabets are allowed for Location Manager Name"
                  )
                  .strict(true),
                assistantManager: Yup.string()
                  .min(
                    1,
                    "Assistant Manager Name can be of min-1 max-255 characters"
                  )
                  .max(
                    255,
                    "Assistant Manager Name can be of min-1 max-255 characters"
                  )
                  .matches(
                    Utils.JSUtils.getYupValidationCheck(/^[aA-zZ\s]+$/),
                    "Only alphabets are allowed for Assistant Manager"
                  )
                  .strict(true),
                emailAddress: Yup.string()
                  .email("Enter valid Email")
                  .min(1, "Email can be of min-1 max-255 characters")
                  .max(255, "Email can be of min-1 max-255 characters")
                  .strict(true),
                line1: Yup.string()
                  .required(`Enter Address line 1`)
                  .min(1, "Address line 1 can be of min-1 max-255 characters")
                  .max(255, "Address line 1 can be of min-1 max-255 characters")
                  .strict(true),
                city: Yup.string()
                  // .required(`Enter City`)
                  .min(1, "City can be of min-1 max-255 characters")
                  .max(255, "City can be of min-1 max-255 characters")
                  .matches(
                    Utils.JSUtils.getYupValidationCheck(/^[aA-zZ\s]+$/),
                    "Only alphabets are allowed for City"
                  )
                  .strict(true),
                country: Yup.object().required("Select Country"),
                state: Yup.object().required(`Select State`),
                // postalcode: Yup.string()
                //   .required(`Enter Zip Code`)
                //   .matches(/^[\w\s\W]+$/, "Enter valid Zip Code")
                //   .min(3, "Zip Code can be of min-3 max-10 characters")
                //   .max(10, "Zip Code can be of min-3 max-10 characters")
                //   .strict(true),
                latitude: Yup.string()
                  .required(`Enter Latitude Position`)
                  .matches(
                    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/,
                    `Enter valid Latitude Position`
                  ),
                longitude: Yup.string()
                  .required(`Enter Longitude Position`)
                  .matches(
                    /^\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
                    `Enter valid Longitude Position`
                  ),
              })}
              onSubmit={async (values, { resetForm, setErrors }) => {
                let region = values.state?.name
                  ? values.state?.name?.trim()
                  : values?.state
                let country = values.country?.name
                  ? values.country?.name?.trim()
                  : values?.country
                setAlert(false)
                setLoading(true)
                let phoneNumberOnly = values.phoneNumber.slice(
                  values.countryCode.length
                )
                phoneNumberOnly = phoneNumberOnly === "" ? "" : phoneNumberOnly

                let countryCodeOnly =
                  phoneNumberOnly === "" ? "" : values.countryCode

                let addressDetails = values?.country?.zipCode
                  ? `${values?.postalcode}`
                  : `${values?.city},${values?.state?.name},${values?.country?.name}`
                let addressresponse = await ServiceAPI.fetchZipcodeDetails(
                  addressDetails,
                  true
                )
                let body = {
                  ...props.sendBackData,
                  locationId: values.location,
                  locationName: values.locationName?.trim(),
                  locationManager: values.locationManager?.trim(),
                  assistantManager: values.assistantManager?.trim(),
                  locationSupportEmail: values.emailAddress,
                  phoneNumber: {
                    countryCode: countryCodeOnly,
                    number: phoneNumberOnly,
                  },
                  locationSize: props.data.locationSize,
                  locationAddress: {
                    line1: values.line1?.trim(),
                    line2: values.line2?.trim(),
                    postalcode: values.postalcode,
                    city: values.city?.trim(),
                    region: region,
                    country: country,
                  },
                  locationGeoPoint: {
                    type: "Point",
                    coordinates: [
                      parseFloat(values.longitude),
                      parseFloat(values.latitude),
                    ],
                  },
                  locationTimezone: addressresponse?.data?.[0]?.timezone,
                }
                if (
                  values?.country?.zipCode === false ||
                  (values?.country?.zipCode &&
                    _.lowerCase(addressresponse?.data?.[0]?.state) ===
                      _.lowerCase(values?.state?.name))
                ) {
                  let response = await ServiceAPI.fetchStoreCommonData(
                    API_END_POINTS.updateLocation,
                    body
                  )
                  await setAlertType(_.get(response, "data.status", "error"))
                  if (await (_.get(response, "data.status") === "success")) {
                    await setAlertMessage(
                      JSUtils.formatMessageCode(
                        response?.data,
                        t,
                        "Try Again Later!"
                      )
                    )
                  } else {
                    await setAlertMessage(
                      JSUtils.formatMessageCode(
                        response?.response?.data,
                        t,
                        "Try Again Later!"
                      )
                    )
                  }
                  setEdit(false)
                  await setAlert(true)
                  await setLoading(false)
                  setTimeout(() => {
                    props.fetchData()
                  }, 2000)
                  setErrorMessage({
                    ...errorMessage,
                    zipcode: "",
                  })
                } else {
                  let zipCodeErr = ""
                  if (values?.country?.zipCode) {
                    if (values?.postalcode === "") {
                      zipCodeErr = t("Enter Zip Code")
                    } else {
                      zipCodeErr = t("Zip_Code_Validation")
                    }
                  }
                  setErrorMessage({
                    ...errorMessage,
                    zipcode: zipCodeErr,
                  })
                  await setLoading(false)
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                resetForm,
              }) => {
                const displayPhoneNumber =
                  values.phoneNumber.slice(values.countryCode.length).length ===
                  0
                    ? "-"
                    : values?.phoneNumber
                const handleKeyPress = (e, type) => {
                  if (type === "number") {
                    if (e.which === 32) {
                      e.preventDefault()
                    }
                  } else {
                    if (e.which === 32 && e.target.value === "") {
                      e.preventDefault()
                    }
                  }
                }

                const fetchStateData = async (value, type) => {
                  setLoading(true)
                  let stateList = await handleFetchStateData(
                    value,
                    type,
                    "formik"
                  )
                  setLoading(false)
                  setFieldValue([type], stateList)
                }

                const handleCountry = (e, value) => {
                  if (value !== null) {
                    setFieldValue("country", value)
                    setFieldValue("state", "")
                    setFieldValue("postalcode", "")
                    fetchStateData(value, "stateList")
                    onGlobalUpdate()
                  } else {
                    setFieldValue("country", "")
                    setFieldValue("state", "")
                    setFieldValue("postalcode", "")
                    fetchStateData({ code: "", id: "", name: "" }, "stateList")
                  }
                }

                const handleState = (e, value) => {
                  if (value !== null) {
                    setAllowPrompt(true)
                  }
                  setFieldValue("state", value)
                  onGlobalUpdate()
                }

                const countryInputChange = () => {
                  setFieldValue(
                    "country",
                    values?.country === ""
                      ? props?.defaultCountry
                      : values?.country
                  )
                }
                const stateInputChange = () => {
                  setFieldValue("state", values?.state)
                }

                const onChangeZipcode = async (props) => {
                  setLoading(false)
                  var zipCode = props?.target?.value
                  if (zipCode?.length >= 3) {
                    await setLoading(true)
                    let response = await ServiceAPI.fetchZipcodeDetails(
                      zipCode,
                      true
                    )
                    if (response?.data && response?.data.length > 0) {
                      setFieldValue("city", _.get(response, "data[0].city", ""))
                      setFieldValue(
                        "state",
                        _.get(response, "data[0].state", "")
                      )
                      setFieldValue(
                        "country",
                        _.get(response, "data[0].country", "")
                      )
                      setFieldValue(
                        "timezone",
                        _.get(response, "data[0].timezone", "")
                      )
                    } else {
                      setFieldValue("city", "")
                      setFieldValue("state", "")
                      setFieldValue("country", "")
                    }
                    await setLoading(false)
                  } else {
                    setFieldValue("city", "")
                    setFieldValue("state", "")
                    setFieldValue("country", "")
                  }
                }

                const handleChangeValue = (event) => {
                  let formattedValue = event.target.value.trim().replace(
                    /(\s{2,})/g,
                    " "
                  )
                  setFieldValue(event.target.name, formattedValue)
                }
                return (
                  <FormMainWrapper
                    onSubmit={handleSubmit}
                    style={{ paddingBottom: "10px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        height: "30px",
                        position: "relative",
                      }}
                    >
                      {edit ? (
                        <div
                          style={{
                            top: "5px",
                            right: "14px",
                            position: "absolute",
                          }}
                        >
                          <Tooltip title={t("Cancel")} placement="top">
                            <IconButton
                              onClick={() => {
                                setEdit(false)
                                setAnyChanges(false)
                                resetForm()
                                setAllowPrompt(false)
                              }}
                              style={{ padding: "9px" }}
                            >
                              <Slash style={{ height: "20px", color: "red" }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t("Save")} placement="top">
                            <IconButton
                              type="submit"
                              onClick={() => {
                                setAnyChanges(false)
                              }}
                              style={{
                                padding: "9px",
                                pointerEvents: `${
                                  isAnyChanges ? "unset" : "none"
                                }`,
                              }}
                            >
                              <CheckCircleIcon
                                style={{
                                  color: `${isAnyChanges ? "#4CAF50" : "#CCC"}`,
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : (
                        <Tooltip title={t("Edit")} placement="top">
                          <IconButton
                            disabled={isWriteAllowed === true ? false : true}
                            aria-label="delete"
                            onClick={() => {
                              setEdit(true)
                            }}
                            size="small"
                            style={{
                              top: "11px",
                              right: "14px",
                              position: "absolute",
                              padding: "9px",
                            }}
                          >
                            <Edit style={{ height: "20px" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                    <Grid
                      container
                      row="true"
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      xl={12}
                    >
                      <Grid
                        item
                        lg={3}
                        md={6}
                        sm={6}
                        xs={12}
                        className={classes.divider}
                      >
                        <Typography
                          variant="h5"
                          className={`${classes.textHeader} makeStyles-textHeader-support`}
                        >
                          <Hash className={classes.icon} />{" "}
                          {t("Location Number")}{" "}
                          <span style={{ color: "red" }}>
                            {" "}
                            {edit ? "*" : ""}{" "}
                          </span>
                        </Typography>
                        {edit ? (
                          <TextField
                            type="text"
                            disabled
                            defaultValue={values.location}
                            variant="outlined"
                            name="location"
                            placeholder={t("Location Number")}
                            error={Boolean(touched.location && errors.location)}
                            helperText={touched.location && t(errors.location)}
                            onBlur={handleBlur}
                            onChange={(props) => {
                              if (values.location !== null) {
                                setAllowPrompt(true)
                              }
                              props.persist()
                              handleChangeValue(props)
                              onGlobalUpdate()
                            }}
                            onKeyPress={(e) => handleKeyPress(e, "number")}
                            size="small"
                            className={classes.textField}
                            style={{ backgroundColor: "#E0E0E0" }}
                          />
                        ) : (
                          <Typography variant="h4" className={classes.text}>
                            {values.location}
                          </Typography>
                        )}
                      </Grid>

                      <Grid
                        item
                        lg={3}
                        md={6}
                        sm={6}
                        xs={12}
                        className={`${
                          isTablet ? classes.noDivider : classes.divider
                        }`}
                      >
                        <Typography
                          variant="h5"
                          className={`${classes.textHeader} makeStyles-textHeader-support`}
                        >
                          <MapPin className={classes.icon} />
                          {t("Location Name")}{" "}
                          <span style={{ color: "red" }}>
                            {" "}
                            {edit ? "*" : ""}{" "}
                          </span>
                        </Typography>
                        {edit ? (
                          <TextField
                            defaultValue={values.locationName}
                            value={values.locationName}
                            variant="outlined"
                            name="locationName"
                            autoComplete="off"
                            placeholder={t("Location Name")}
                            error={Boolean(
                              touched.locationName && errors.locationName
                            )}
                            helperText={
                              touched.locationName && t(errors.locationName)
                            }
                            onKeyPress={(e) => handleKeyPress(e, "text")}
                            onBlur={handleBlur}
                            onChange={(props) => {
                              if (values.locationName !== null) {
                                setAllowPrompt(true)
                              }
                              handleChangeValue(props)
                              onGlobalUpdate()
                            }}
                            className={classes.textField}
                            size="small"
                          />
                        ) : (
                          <Typography variant="h4" className={classes.text}>
                            {values.locationName}
                          </Typography>
                        )}
                      </Grid>

                      <Grid
                        item
                        lg={3}
                        md={6}
                        sm={6}
                        xs={12}
                        className={classes.divider}
                      >
                        <Typography
                          variant="h5"
                          className={`${classes.textHeader} makeStyles-textHeader-support`}
                        >
                          <Phone className={classes.icon} />
                          {t("Phone Number")}{" "}
                        </Typography>
                        {edit ? (
                          <div
                            style={{ marginLeft: "15px", marginBottom: "5px" }}
                          >
                            <Adm.PhoneField
                              name="phoneNumber"
                              autoComplete="off"
                              entity="Location"
                              onKeyPress={(e) => handleKeyPress(e, "number")}
                              defaultValue={
                                values.phoneNumber !== "0"
                                  ? values.phoneNumber.toString()
                                  : ""
                              }
                              className="location-base-phone-field"
                              onUpdate={({
                                value,
                                dialCode,
                                format,
                                countryCode,
                              }) => {
                                const formatText =
                                  format !== undefined &&
                                  format
                                    .replace("+", "")
                                    .replace("-", "")
                                    .replaceAll(" ", "")
                                setPhoneField({
                                  phoneCountryCode: countryCode,
                                  phoneCount: formatText.length,
                                  phoneCode: dialCode,
                                })
                                setFieldValue("phoneNumber", value)
                                setFieldValue("countryCode", dialCode)
                                onGlobalUpdate()
                              }}
                              defaultErrorValue={Boolean(
                                touched.phoneNumber && errors.phoneNumber
                              )}
                            />
                          </div>
                        ) : (
                          <Typography variant="h4" className={classes.text}>
                            {displayPhoneNumber}
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={6}
                        sm={6}
                        xs={12}
                        className={`${classes.noDivider}`}
                      >
                        <Typography
                          variant="h5"
                          className={`${classes.textHeader} makeStyles-textHeader-support`}
                        >
                          <Mail className={classes.icon} />
                          {t("Email Address")}{" "}
                        </Typography>
                        {edit ? (
                          <TextField
                            defaultValue={values.emailAddress}
                            value={values.emailAddress}
                            variant="outlined"
                            name="emailAddress"
                            autoComplete="off"
                            placeholder={t("Email")}
                            onKeyPress={(e) => handleKeyPress(e, "text")}
                            error={Boolean(
                              touched.emailAddress && errors.emailAddress
                            )}
                            helperText={
                              touched.emailAddress && t(errors.emailAddress)
                            }
                            onBlur={handleBlur}
                            onChange={(props) => {
                              if (values.emailAddress !== null) {
                                setAllowPrompt(true)
                              }
                              handleChangeValue(props)
                              onGlobalUpdate()
                            }}
                            className={classes.textField}
                            size="small"
                          />
                        ) : (
                          <Typography
                            variant="h4"
                            className={classes.textemail}
                          >
                            {values.emailAddress ? values.emailAddress : "-"}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>

                    <Divider
                      variant="middle"
                      style={{ marginTop: "15px", marginBottom: "15px" }}
                    />
                    <Grid container row="true">
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={6}
                        xs={6}
                        className={classes.divider}
                      >
                        <Typography
                          variant="h5"
                          className={classes.textHeader1}
                        >
                          {t("Location Manager")}{" "}
                          <span style={{ color: "red" }}>
                            {edit ? "*" : ""}{" "}
                          </span>
                        </Typography>
                        {edit ? (
                          <TextField
                            defaultValue={values.locationManager}
                            value={values.locationManager}
                            variant="outlined"
                            autoComplete="off"
                            onKeyPress={(e) => handleKeyPress(e, "text")}
                            name="locationManager"
                            placeholder={t("Location Manager")}
                            className={classes.textField}
                            error={Boolean(
                              touched.locationManager && errors.locationManager
                            )}
                            helperText={
                              touched.locationManager &&
                              t(errors.locationManager)
                            }
                            onBlur={handleBlur}
                            onChange={(props) => {
                              if (values.locationManager !== null) {
                                setAllowPrompt(true)
                              }
                              handleChangeValue(props)
                              onGlobalUpdate()
                            }}
                            size="small"
                          />
                        ) : (
                          <Typography className={classes.text}>
                            {values.locationManager
                              ? values.locationManager
                              : "-"}
                          </Typography>
                        )}
                      </Grid>

                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={6}
                        xs={6}
                        className={`${
                          isTablet ? classes.noDivider : classes.divider
                        }`}
                      >
                        <Typography
                          variant="h5"
                          className={classes.textHeader1}
                        >
                          {t("Assistant Manager")}{" "}
                        </Typography>
                        {edit ? (
                          <TextField
                            defaultValue={values.assistantManager}
                            value={values.assistantManager}
                            variant="outlined"
                            autoComplete="off"
                            name="assistantManager"
                            placeholder={t("Assistant Manager")}
                            onKeyPress={(e) => handleKeyPress(e, "text")}
                            error={Boolean(
                              touched.assistantManager &&
                                errors.assistantManager
                            )}
                            helperText={
                              touched.assistantManager &&
                              t(errors.assistantManager)
                            }
                            onBlur={handleBlur}
                            className={classes.textField}
                            onChange={(props) => {
                              if (values.assistantManager !== null) {
                                setAllowPrompt(true)
                              }
                              handleChangeValue(props)
                              onGlobalUpdate()
                            }}
                            size="small"
                          />
                        ) : (
                          <Typography className={classes.text}>
                            {values.assistantManager
                              ? values.assistantManager
                              : "-"}
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        container
                        item
                        lg={4}
                        xl={4}
                        md={4}
                        sm={6}
                        xs={12}
                        dirextion="row"
                        className={`${classes.noDivider} cls-location-name-wrapper`}
                      >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography
                            variant="h5"
                            className={classes.textHeader1}
                          >
                            {t("Geographic Position")}{" "}
                            <span style={{ color: "red" }}>
                              {" "}
                              {edit ? "*" : ""}{" "}
                            </span>
                          </Typography>
                        </Grid>
                        {edit ? (
                          <>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                              <TextField
                                defaultValue={values.latitude}
                                value={values.latitude}
                                variant="outlined"
                                name="latitude"
                                label={t("Latitude")}
                                placeholder={t("Latitude")}
                                onKeyPress={(e) => handleKeyPress(e, "text")}
                                error={Boolean(
                                  touched.latitude && errors.latitude
                                )}
                                helperText={
                                  touched.latitude && t(errors.latitude)
                                }
                                onBlur={handleBlur}
                                className={`${classes.textField} cls-ledgent-input-label`}
                                onChange={(props) => {
                                  if (values.latitude !== null) {
                                    setAllowPrompt(true)
                                  }
                                  handleChangeValue(props)
                                  onGlobalUpdate()
                                }}
                                size="small"
                              />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                              <TextField
                                defaultValue={values.longitude}
                                value={values.longitude}
                                variant="outlined"
                                name="longitude"
                                label={t("Longitude")}
                                placeholder={t("Longitude")}
                                onKeyPress={(e) => handleKeyPress(e, "text")}
                                error={Boolean(
                                  touched.longitude && errors.longitude
                                )}
                                helperText={
                                  touched.longitude && t(errors.longitude)
                                }
                                onBlur={handleBlur}
                                className={`${classes.textField} cls-ledgent-input-label`}
                                onChange={(props) => {
                                  if (values.longitude !== null) {
                                    setAllowPrompt(true)
                                  }
                                  handleChangeValue(props)
                                  onGlobalUpdate()
                                }}
                                size="small"
                              />
                            </Grid>
                          </>
                        ) : (
                          <Typography className={classes.text}>
                            {values.latitude &&
                              values.longitude &&
                              `${values.latitude},${values.longitude}`}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Divider
                      variant="middle"
                      style={{ marginTop: "15px", marginBottom: "15px" }}
                    />

                    <Typography
                      variant="h3"
                      style={{
                        position: "relative",
                        paddingBottom: "20px",
                        paddingTop: "3px",
                        paddingLeft: "25px",
                      }}
                    >
                      {t("Address Info")}{" "}
                      <span style={{ color: "red" }}>{edit ? "*" : ""} </span>
                    </Typography>
                    <Grid
                      container
                      row="true"
                      style={{ padding: "10px 5px", margin: "0px" }}
                      spacing={4}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <Grid item lg={2} md={4} sm={4} xs={6} xl={2}>
                        <Typography
                          variant="h5"
                          className={classes.textHeader1}
                        >
                          {t("Address Line 1")}
                        </Typography>
                        {edit ? (
                          <TextField
                            defaultValue={values.line1}
                            value={values.line1}
                            variant="outlined"
                            autoComplete="off"
                            name="line1"
                            placeholder={t("Address Line 1")}
                            onKeyPress={(e) => handleKeyPress(e, "text")}
                            className={classes.textField}
                            error={Boolean(touched.line1 && errors.line1)}
                            helperText={touched.line1 && t(errors.line1)}
                            onBlur={handleBlur}
                            onChange={(props) => {
                              if (values.line1 !== null) {
                                setAllowPrompt(true)
                              }
                              handleChangeValue(props)
                              onGlobalUpdate()
                            }}
                            size="small"
                          />
                        ) : (
                          <Typography className={classes.text}>
                            {values.line1}
                          </Typography>
                        )}
                      </Grid>

                      <Grid item lg={2} md={4} sm={4} xs={6} xl={2}>
                        <Typography
                          variant="h5"
                          className={classes.textHeader1}
                        >
                          {t("Address Line 2")}
                        </Typography>
                        {edit ? (
                          <TextField
                            defaultValue={values.line2}
                            value={values.line2}
                            variant="outlined"
                            name="line2"
                            autoComplete="off"
                            placeholder={t("Address Line 2")}
                            onKeyPress={(e) => handleKeyPress(e, "text")}
                            className={classes.textField}
                            error={Boolean(touched.line2 && errors.line2)}
                            helperText={touched.line2 && t(errors.line2)}
                            onBlur={handleBlur}
                            onChange={(props) => {
                              if (values.line2 != null) {
                                setAllowPrompt(true)
                              }
                              handleChangeValue(props)
                              onGlobalUpdate()
                            }}
                            size="small"
                          />
                        ) : (
                          <Typography className={classes.text}>
                            {values.line2 || "-"}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={2} md={4} sm={4} xs={6} xl={2}>
                        <Typography
                          variant="h5"
                          className={classes.textHeader1}
                        >
                          {t("Country")}
                          {edit && <span style={{ color: "red" }}>*</span>}
                        </Typography>
                        {edit ? (
                          <>
                            {/* <TextField
                              variant="outlined"
                              size="small"
                              className={classes.dropDownInput}
                              type="text"
                              autoComplete="off"
                              name="country"
                              placeholder="Country"
                              value={values?.country?.name || values.country || ""}
                              disabled={true}
                              style={{ backgroundColor: '#f6f6f6' }}
                            /> */}
                            <Adm.DropdownFilter
                              list={countryList}
                              defaultValue={props?.defaultCountry}
                              selectedValue={values.country}
                              onChangeEvent={handleCountry}
                              popupOpen={countryOpen}
                              onInputChangeEvent={countryInputChange}
                              setPopupOpen={setCountryOpen}
                              disableClearable={true}
                              classNameTextField={`${classes.autoCompleteHeight} makeStyles-autoCompleteHeight-support`}
                              touchedControl={touched.country}
                              errorControl={t(errors.country)}
                              placeholder={t("Select Country")}
                              style={{
                                width: "90%",
                                marginBottom: "20px",
                                height: "36px",
                                marginLeft: "16px",
                              }}
                            />
                          </>
                        ) : (
                          <Typography className={classes.text}>
                            {isString(values?.country)
                              ? values?.country
                              : !isEmpty(values?.country?.name)
                              ? values?.country?.name
                              : ""}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={2} md={4} sm={4} xs={6} xl={2}>
                        <Typography
                          variant="h5"
                          className={classes.textHeader1}
                        >
                          {t("State")}
                          {edit && <span style={{ color: "red" }}>*</span>}
                        </Typography>
                        {edit ? (
                          <>
                            {/* <TextField
                              variant="outlined"
                              size="small"
                              className={classes.dropDownInput}
                              type="text"
                              autoComplete="off"
                              name="state"
                              placeholder={t("State")}
                              value={values?.state?.name || values.state || ""}
                              disabled={true}
                              style={{ backgroundColor: '#f6f6f6' }}
                            /> */}
                            <Adm.DropdownFilter
                              list={values?.stateList || props?.stateList}
                              defaultValue={props?.defaultState}
                              selectedValue={
                                values?.state || { id: 0, name: "", code: "" }
                              }
                              onChangeEvent={handleState}
                              popupOpen={stateOpen}
                              onInputChangeEvent={stateInputChange}
                              setPopupOpen={setStateOpen}
                              disableClearable={true}
                              classNameTextField={`${classes.autoCompleteHeight} makeStyles-autoCompleteHeight-support`}
                              touchedControl={touched.state}
                              errorControl={t(errors.state)}
                              placeholder={t("Select State")}
                              style={{
                                width: "90%",
                                marginBottom: "20px",
                                height: "36px",
                                marginLeft: "16px",
                              }}
                            />
                          </>
                        ) : (
                          <Typography className={classes.text}>
                            {isString(values?.state)
                              ? values?.state
                              : !isEmpty(values?.state?.name)
                              ? values?.state?.name
                              : ""}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={2} md={4} sm={4} xs={6} xl={2}>
                        <Typography
                          variant="h5"
                          className={classes.textHeader1}
                        >
                          {t("City")}
                        </Typography>
                        {edit ? (
                          <TextField
                            value={values.city}
                            variant="outlined"
                            name="city"
                            autoComplete="off"
                            placeholder={t("City")}
                            className={classes.textField}
                            size="small"
                            onChange={(props) => {
                              handleChangeValue(props)
                            }}
                            //disabled={true}
                            //style={{ backgroundColor: '#f6f6f6' }}
                          />
                        ) : (
                          <Typography className={classes.text}>
                            {values.city || "-"}
                          </Typography>
                        )}
                      </Grid>
                      {!(values?.country?.zipCode === false) ? (
                        <Grid item lg={2} md={4} sm={4} xs={6} xl={2}>
                          <Typography
                            variant="h5"
                            className={classes.textHeader1}
                          >
                            {t("Zip Code")}
                          </Typography>
                          {edit ? (
                            <TextField
                              defaultValue={values.postalcode}
                              value={values.postalcode}
                              variant="outlined"
                              name="postalcode"
                              autoComplete="off"
                              placeholder={t("Zip Code")}
                              className={classes.textField}
                              hidden={values?.country?.zipCode === false}
                              error={
                                !_.isEmpty(errorMessage) &&
                                values?.country?.zipCode &&
                                errorMessage.zipcode
                              }
                              helperText={
                                values?.country?.zipCode && errorMessage.zipcode
                              }
                              onBlur={handleBlur}
                              // onKeyPress={(e) => handleKeyPress(e, "number")}
                              onChange={(props) => {
                                //onChangeZipcode(props)
                                if (values.postalcode !== null) {
                                  setAllowPrompt(true)
                                }
                                handleChangeValue(props)
                                onGlobalUpdate()
                              }}
                              size="small"
                            />
                          ) : (
                            <Typography className={classes.text}>
                              {values.postalcode || "-"}
                            </Typography>
                          )}
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </FormMainWrapper>
                )
              }}
            </Formik>
          </>
        )}
      </Card>
      <>
        {alert ? (
          <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
        ) : null}
      </>
    </div>
  )
}
export { LocationAddress }
export default LocationAddress
